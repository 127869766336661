<template>
    <Modal>
        <template v-slot:modalContent>
            <svg class="close-button" @click="$emit('closeModal')" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
            </svg>
            <div class="modal-body">
                <div class="modal-header">
                    <h3>{{ $t('Send invoice') }}</h3>
                </div>

                <form @submit.prevent="" class="invite-form">

                    <div class="form-group">
                        <input v-model="email.subject" type="text" :class="['form-control', {'has-error': errors['subject']}]" :placeholder="$t('Subject')">

                        <FormErrors v-if="errors[`subject`]" :errors="errors[`subject`]" />
                    </div>

                    <div class="form-group">
                        <textarea v-model="email.message" :class="['form-control', {'has-error': errors['message']}]" :placeholder="$t('Email message')"/>

                        <FormErrors v-if="errors[`message`]" :errors="errors[`message`]" />
                    </div>

                    <button class="btn btn-primary" :disabled="disabled" @click="sendInvoice">{{ $t('Send') }}</button>
                </form>

            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/widgets/Modal';
import FormErrors from '@/components/widgets/FormErrors';
import {invoiceService} from "@/services/invoiceService";

export default {
    name: "SendInvoiceComponent",
    components: {
        Modal,
        FormErrors
    },
    data() {
        return {
        }
    },
    emits: ['closeModal'],
    props: ['invoice'],
    computed: {
        email() {
            return this.$store.getters.invoiceEmail
        }
    },
    methods: {
        sendInvoice() {
            this.disabled = true
            invoiceService.sendInvoice(this.invoice.id, this.email)
                .then(response => {
                    this.invoice.status_id = response.data.status_id
                    this.invoice.status_name = response.data.status_name
                    this.$emit('closeModal')
                })
                .catch(error => this.errors = error.response.data.errors || [])
                .finally(() => this.disabled = false)
        }
    }
}
</script>

<style scoped>

</style>