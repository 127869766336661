<template>
    <div class="container-fluid view invoices">
        <div class="content-view borderless without-sidebar">
            <div class="table-view invoices-table-view">
                <div class="action-header">
                    <h1>{{ $t('Invoices') }}<span>{{ invoices.length ? invoices.length : 0 }}</span></h1>
                    <div class="title-search">
                        <div class="form-group icon-input wide-input">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="20.155" height="20.176" viewBox="0 0 20.155 20.176">
                                <path d="M30.576 29.224l-3.73-3.73a8.9 8.9 0 1 0-1.427 1.452l3.73 3.73a.988.988 0 0 0 .7.3.909.909 0 0 0 .7-.3 1.052 1.052 0 0 0 .027-1.452zm-3.755-9.387a7.008 7.008 0 1 1-2.053-4.982 7.005 7.005 0 0 1 2.053 4.982z" transform="translate(-10.7 -10.8)"/>
                            </svg>
                            <input type="text" class="form-control" :placeholder="$t('Search invoice')">
                        </div>
                    </div>
                    <div class="actions">
                        <router-link :to="{name: 'AddInvoice'}">
                            <button type="button" class="btn btn-grey">
                                {{ $t('Add') }}
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.285" height="18.285" viewBox="0 0 18.285 18.285">
                                    <g>
                                        <g>
                                            <path d="M8.148 2.5H4.87A2.37 2.37 0 0 0 2.5 4.87v3.278a2.37 2.37 0 0 0 2.37 2.37h3.278a2.37 2.37 0 0 0 2.37-2.37V4.87a2.37 2.37 0 0 0-2.37-2.37zm.339 5.648a.339.339 0 0 1-.339.339H4.87a.339.339 0 0 1-.339-.339V4.87a.339.339 0 0 1 .339-.339h3.278a.339.339 0 0 1 .339.339zM18.415 2.5h-3.278a2.37 2.37 0 0 0-2.37 2.37v3.278a2.37 2.37 0 0 0 2.37 2.37h3.278a2.37 2.37 0 0 0 2.37-2.37V4.87a2.37 2.37 0 0 0-2.37-2.37zm.339 5.648a.339.339 0 0 1-.339.339h-3.278a.339.339 0 0 1-.339-.339V4.87a.339.339 0 0 1 .339-.339h3.278a.339.339 0 0 1 .339.339zM8.148 12.767H4.87a2.37 2.37 0 0 0-2.37 2.37v3.278a2.37 2.37 0 0 0 2.37 2.37h3.278a2.37 2.37 0 0 0 2.37-2.37v-3.278a2.37 2.37 0 0 0-2.37-2.37zm.339 5.648a.339.339 0 0 1-.339.339H4.87a.339.339 0 0 1-.339-.339v-3.278a.339.339 0 0 1 .339-.337h3.278a.339.339 0 0 1 .339.339zm12.3-1.639a1.016 1.016 0 0 1-1.016 1.016h-1.979v1.978a1.016 1.016 0 1 1-2.032 0v-1.978h-1.977a1.016 1.016 0 1 1 0-2.032h1.977v-1.977a1.016 1.016 0 1 1 2.032 0v1.977h1.978a1.016 1.016 0 0 1 1.015 1.016z" transform="translate(-2.5 -2.5) translate(2.5 2.5) translate(-2.5 -2.5)"/>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </router-link>
                    </div>
                </div>

                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">{{ $t('Client') }}</th>
                            <th scope="col">{{ $t('Invoice Nr') }}</th>
                            <th scope="col">{{ $t('Total') }}</th>
                            <th scope="col">{{ $t('Total VAT') }}</th>
                            <th scope="col">{{ $t('Total with VAT') }}</th>
                            <th scope="col">{{ $t('Status') }}</th>
                            <th scope="col">{{ $t('Invoice date') }}</th>
                            <th scope="col">{{ $t('Due date') }}</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="invoice in invoices" :key="invoice.id" :class="invoice.deleted_at?'bg-danger':''">
                            <td>
                                <router-link :to="{ name: 'ViewClient', params: { id: invoice.company_id } }">{{ invoice.company_name }}</router-link>
                            </td>
                            <td>{{ invoice.invoice_nr }}</td>
                            <td>{{ invoice.total }}</td>
                            <td>{{ invoice.total_vat }}</td>
                            <td>{{ invoice.total_with_vat }}</td>
                            <td class="pe-20">
                                <Multiselect
                                    :canClear="false"
                                    v-model="invoice.status_id"
                                    :options="invoiceStatuses"
                                    :placeholder="$t('Select status')"
                                    class="form-control"
                                    @input="invoiceStatusChanged(invoice.id, $event)"
                                />
                            </td>
                            <td>{{ invoice.invoice_date }}</td>
                            <td>{{ invoice.due_date }}</td>
                            <td class="text-end invoice-actions">
                                <router-link :to="{ name: 'ViewInvoice', params: { id: invoice.id }}">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.705" height="13.199" viewBox="0 0 21.705 13.199">
                                        <g>
                                            <g>
                                                <path d="M25.134 27.331C22.445 23.3 18.559 21 14.452 21S6.46 23.3 3.771 27.331L3.6 27.6l.171.269C6.46 31.9 10.346 34.2 14.452 34.2s7.993-2.3 10.681-6.331l.167-.269zM14.452 31.51a3.911 3.911 0 1 1 3.911-3.91 3.922 3.922 0 0 1-3.911 3.91z" transform="translate(-3.6 -21) translate(3.6 21) translate(-3.6 -21)"/>
                                            </g>
                                        </g>
                                    </svg>
                                </router-link>
                                <router-link :to="{ name: 'EditInvoice', params: { id: invoice.id }}">
                                    <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                                        <g>
                                            <g>
                                                <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                            </g>
                                        </g>
                                    </svg>
                                </router-link>
                                <a :href="invoice.id + '/send'" @click.prevent="sendInvoice(invoice)">
                                    <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                                        <g>
                                            <g>
                                                <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                                <template v-if="invoice.deleted_at">
                                    <a :href="invoice.id + '/restore'" @click.prevent="restoreInvoice(invoice)">
                                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                                            <g>
                                                <g>
                                                    <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                </template>
                                <template v-else>
                                    <a :href="invoice.id + '/delete'" @click.prevent="deleteInvoice(invoice)">
                                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                                            <g>
                                                <g>
                                                    <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                </template>
                                <a :href="invoice.pdf ? invoice.pdf : null" target="_blank">
                                    <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                                        <g>
                                            <g>
                                                <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <send-invoice-component v-if="showSendInvoice" @closeModal="showSendInvoice=false" :invoice="invoice" />

    </div>
</template>

<script>
import {invoiceService} from "@/services/invoiceService";
import SendInvoiceComponent from "@/components/invoices/SendInvoiceComponent";
import Multiselect from '@vueform/multiselect'
import HeaderSearch from '@/mixins/HeaderSearch'

export default {
    name: "Invoices",
    mixins: [ HeaderSearch ],
    data() {
        return {
            invoice: null,
            showSendInvoice: false,
        }
    },
    computed: {
        invoices() {
            return this.$store.state.invoices.invoices
        },
        invoiceStatuses() {
            return this.$store.getters.invoiceStatuses
        },
    },
    created() {
        invoiceService.getInvoices()
            .then(response => this.$store.commit('setInvoices', response.data.data))
    },
    methods: {
        searchFromHeader(keyword) {
            this.search = keyword;
            alert('Searched')
        },
        invoiceStatusChanged(invoiceId, statusId) {
            invoiceService.updateInvoiceStatus(invoiceId, statusId)
        },
        deleteInvoice(invoice) {
            let areYouSure = confirm('Are you sure?')

            if (areYouSure) {
                invoiceService.deleteInvoice(invoice.id)
                    .then(response => {
                        invoice.deleted_at = response.data.deleted_at
                        invoice.status_id = response.data.status_id
                    })
            }
        },
        restoreInvoice(invoice) {
            let areYouSure = confirm('Are you sure?')

            if (areYouSure) {
                invoiceService.restoreInvoice(invoice.id)
                    .then(response => {
                        delete invoice.deleted_at
                        invoice.status_id = response.data.status_id
                    })
            }
        },
        sendInvoice(invoice) {
            if (! invoice.deleted_at) {
                this.invoice = invoice
                this.showSendInvoice = true
            }
        }
    },
    components: {
        SendInvoiceComponent,
        Multiselect
    }
}
</script>